import { getToken, setToken } from '@/utils/authority';
// import { Dispatch } from 'redux';
import * as constants from '@/constants';
import { notification } from 'antd';
import { setlocalStorageData } from '@/components/readLocalStorage';
import { logoutFromServer } from '@/services/user';
import {
  utilSetLocalStorage,
  utilGetLocalStorage,
  utilClearLocalStorage,
} from '@/utils/storage_utils';

declare let Stripe: any;

const codeMessage = {
  200: 'The server successfully returned the requested data.',
  201: 'New or modified data is successful.',
  202: 'A request has entered the background queue (asynchronous task).',
  204: 'The data was deleted successfully.',
  400: 'The request was made with an error and the server did not perform any operations to create or modify data.',
  401: 'User does not have permission (token, username, password is incorrect).',
  403: 'The user is authorized, but access is forbidden.',
  404: 'The request is made for a record that does not exist or the server does not operate.',
  406: 'The format of the request is not available.',
  410: 'The requested resource is permanently deleted and will not be retrieved.',
  422: 'A validation error occurred when creating an object.',
  500: 'An error occurred on the server. Please check the server.',
  502: 'Gateway error.',
  503: 'The service is unavailable or the server is temporarily overloaded or maintained.',
  504: 'The gateway timed out.',
};
const quotaObj: any = setlocalStorageData();
// let dispatch: Dispatch;

function clearState() {
  setToken(null);
  utilClearLocalStorage();
  // if needed in future...
  return {
    status: 'ok',
    resetPasswordStatus: false,
    token: null,
    currentUser: {},
    signInErrors: {},
    signUpErrors: {},
    passwordResetErrors: {},
  };
}

export async function logoutState() {
  // yield call(logoutFromServer)
  if (utilGetLocalStorage('token') !== null) {
    logoutFromServer();
  }
  clearState();
}

function checkResponseHeaders(response: Response) {
  if (
    response.headers.get('user_api_consumption') &&
    response.headers.get('is_throttling_passed') &&
    response.headers.get('is_upgrade_requested')
  ) {
    const user_api_consumption: string = response.headers.get('user_api_consumption');
    const is_throttling_passed: string = response.headers.get('is_throttling_passed');
    const is_upgrade_requested: string = response.headers.get('is_upgrade_requested');
    utilSetLocalStorage('quota', user_api_consumption);
    utilSetLocalStorage('isThrottling', is_throttling_passed);
    utilSetLocalStorage('isUpgradeRequested', is_upgrade_requested);
    // console.log('set the user api consumption:', user_api_consumption);
    const event: Event = new Event('storage');
    window.dispatchEvent(event);
  }
  // Throw Error
  if (!(response.status >= 200 && response.status <= 299)) {
    const errorText = codeMessage[response.status] || response.statusText;
    const { status, url } = response;
    console.error(status, url);
    if (response.status === 401) {
      if (!url.includes('/auth_management/logout/')) {
        logoutState();
      }
      clearState();
      window.location.href = '/user/login';
    } else {
      throw new Error(errorText);
    }
  }
  return response;
}

const errorHandler = (error: { response: Response }): Response => {
  const { response } = error;
  if (response && response.status) {
    const errorText = codeMessage[response.status] || response.statusText;
    const { status, url } = response;
    console.error(errorText, status, url);
  }
  return response;
};

const tokenHeader = () => {
  const token = getToken();
  if (token) return `Token ${token!.split('"').join('')}`;
  return '';
};

export async function fetchAPIPOST(url: string, params: any) {
  let response = {};
  await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json; charset=UTF-8', Authorization: tokenHeader() },
    body: JSON.stringify(params),
  })
    .then(r => checkResponseHeaders(r))
    .then(response => response.json())
    .then(r => {
      response = r;
    })
    .catch(err => errorHandler({ response: err }));

  return response;
}

export async function fetchAPIUpload(url: string, params: any) {
  let response = {};
  await fetch(url, {
    method: 'POST',
    headers: { Authorization: tokenHeader() },
    body: params,
  })
    .then(r => checkResponseHeaders(r))
    .then(response => response.json())
    .then(r => {
      response = r;
    })
    .catch(err => {
      notification.error({
        description: `Error: ${err}`,
        message: 'There was some error uploading this file',
      });
      errorHandler({ response: err });
    });

  return response;
}

export async function fetchAPIPATCH(url: string, params: any) {
  let response = {};
  await fetch(url, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json; charset=UTF-8', Authorization: tokenHeader() },
    body: JSON.stringify(params),
  })
    .then(r => checkResponseHeaders(r))
    .then(response => response.json())
    .then(r => {
      response = r;
    })
    .catch(err => errorHandler({ response: err }));

  return response;
}

function showFile(blob: Blob, filename: string) {
  const newBlob = new Blob([blob], { type: 'application/pdf' });
  // IE
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob);
    return;
  }
  // For other browsers:
  const data = window.URL.createObjectURL(newBlob);
  const link = document.createElement('a');
  link.href = data;
  link.download = filename;
  link.click();
  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(data);
  }, 100);
}

export async function fetchAPIDownload(urlHead: string, params: any) {
  const url = `${urlHead}?inspection_id=${params.inspection_id}`;
  const fileName = params.document_name
    ? params.document_name
    : `${urlHead.slice(-4)}_Atlas_${params.inspection_id}.pdf`;
  await fetch(url, { headers: { Authorization: tokenHeader() } })
    // .then(response => {
    //   notification.info({
    //     description: 'Download initiated',
    //     message: 'Downloading ' + fileName,
    //   });
    //   return response;
    // })
    .then(response => checkResponseHeaders(response))
    .then(response => {
      if (response.status === 200) {
        notification.success({
          description: 'Download succeeded',
          message: `Downloading ${fileName}`,
        });
      }
      return response;
    })
    .then(response => response.blob())
    .then(response => showFile(response, fileName))
    .catch(err => {
      notification.error({
        description: 'Download failed',
        message: `Downloading ${fileName}`,
      });
      errorHandler({ response: err });
    });
}

export async function fetchAPIGET(url: string) {
  let response;
  await fetch(url, { headers: { Authorization: tokenHeader() } })
    .then(resp => checkResponseHeaders(resp))
    .then(resp => resp.json())
    .then(res => (response = res))
    .catch(err => errorHandler({ response: err }));
  return response;
}

export async function fetchAPIDownloadReport(url: string, fileName: string) {
  await fetch(url, { headers: { Authorization: tokenHeader() } })
    .then(response => checkResponseHeaders(response))
    .then(response => {
      if (response.status === 200) {
        notification.success({
          description: 'Download succeeded',
          message: `Downloading ${fileName}`,
        });
      }
      return response;
    })
    .then(response => response.blob())
    .then(response => showFile(response, fileName))
    .catch(err => {
      notification.error({
        description: 'Download failed',
        message: 'There was some error downloading this file',
      });
      errorHandler({ response: err });
    });
}

export async function fetchAPIDownloadObservationCsv(url: string, params: any, fileName:string) {
  
  await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json; charset=UTF-8', Authorization: tokenHeader() },
    body: JSON.stringify(params),
  })
    .then(response => checkResponseHeaders(response))
    .then(response => {
      if (response.status === 200) {
        notification.success({
          description: 'Download succeeded',
          message: `Downloading`,
        });
      }
      return response;
    })
    .then(response => response.blob())
    .then(response => showFile(response, fileName))
    .catch(err => {
      notification.error({
        description: 'Download failed',
        message: 'There was some error downloading this file',
      });
      errorHandler({ response: err });
    });
}

export async function fetchSiteMap() {
  const link = document.createElement('a');
  link.href = constants.SiteMapEP;
  link.download = 'sitemap.xml';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

function handleResult(result: any) {
  console.log('buy result ::::', result);
  if (result.error) {
    console.log('error while payment ', result.error);
  }
}

export async function fetchAPIPayments(url: string, params: any) {
  let response = {};
  await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json; charset=UTF-8', Authorization: tokenHeader() },
    body: JSON.stringify(params),
  })
    .then(r => checkResponseHeaders(r))
    .then(response => response.json())
    .then(response => {
      const stripe = Stripe(utilGetLocalStorage('stripeKey'));
      stripe
        .redirectToCheckout({
          sessionId: response.sessionId,
        })
        .then(handleResult);
    })
    .then(r => {
      response = r;
    })
    .catch(err => errorHandler({ response: err }));

  return response;
}

export async function fetchAPIGETunAuth(url: string) {
  let response;
  await fetch(url)
    // .then(resp => checkResponseHeaders(resp))
    .then(resp => resp.json())
    .then(res => (response = res))
    .catch(err => errorHandler({ response: err }));

  return response;
}
