export default {
  'menu.welcome': 'Welcome',
  'menu.CAT': 'Comparative Analysis Tool',
  'menu.Analytics': 'Analytics',
  'menu.Daily Companion': 'Daily Companion',
  'menu.Recently Added 483s': 'Recently Added 483s',
  'menu.USFDA Inspections': 'USFDA Inspections',
  'menu.Data Typing': 'Data Typing',
  'menu.MHRA/EU Inspections': 'MHRA/EU Inspections',
  'menu.investigators': 'Investigators',
  'menu.sites': 'Sites',
  'menu.Trending483': 'Trending 483',
  'menu.companyProfile': 'Company Profile',
  'menu.warning_letters': 'Warning Letters',
  'menu.observations': 'Observation Search-Advanced',
  'menu.observationsQS': 'Observation Search-QualitySystems',
  'menu.observationsFTS': 'Observation Search-Text',
  'menu.observationsCS': 'Observation Search- CFR Citation',
  'menu.Recall Data': 'Recall Data',
  'menu.MeDeusa': 'MeDeusa',
  'menu.MeDeusa.MyProfile': 'MyProfile',
  'menu.MeDeusa.Recall': 'Recall',
  'menu.MeDeusa.Registration_listing': 'Registration_listing',
  'menu.MeDeusa.MAUDE': 'MAUDE',
  'menu.drugAnalytics': 'Drug Search',
  'menu.WatchList': 'WatchList',
  'menu.NewsFeed': 'News Feed',
  'menu.internalAudit': 'Internal Audit (Coming Soon)',
  'menu.andasearch': 'ANDA Search (Coming Soon)',
  'menu.more-blocks': 'More Blocks',
  'menu.home': 'Home',
  'menu.login': 'Login',
  'menu.passwordReset': 'Reset Password',
  'menu.signup': 'Sign Up',
  'menu.register': 'Register',
  'menu.register.result': 'Register Result',
  'menu.dashboard': 'Dashboard',
  'menu.dashboard.analysis': 'Analysis',
  'menu.dashboard.monitor': 'Monitor',
  'menu.dashboard.workplace': 'Workplace',
  'menu.exception.403': '403',
  'menu.exception.404': '404',
  'menu.exception.500': '500',
  'menu.form': 'Form',
  'menu.form.basic-form': 'Basic Form',
  'menu.form.step-form': 'Step Form',
  'menu.form.step-form.info': 'Step Form(write transfer information)',
  'menu.form.step-form.confirm': 'Step Form(confirm transfer information)',
  'menu.form.step-form.result': 'Step Form(finished)',
  'menu.form.advanced-form': 'Advanced Form',
  'menu.list': 'List',
  'menu.list.table-list': 'Search Table',
  'menu.table-list': 'Search Table',
  'menu.list.basic-list': 'Basic List',
  'menu.list.card-list': 'Card List',
  'menu.list.search-list': 'Search List',
  'menu.list.search-list.articles': 'Search List(articles)',
  'menu.list.search-list.projects': 'Search List(projects)',
  'menu.list.search-list.applications': 'Search List(applications)',
  'menu.profile': 'Profile',
  'menu.profile.basic': 'Basic Profile',
  'menu.profile.advanced': 'Advanced Profile',
  'menu.result': 'Result',
  'menu.result.success': 'Success',
  'menu.result.fail': 'Fail',
  'menu.exception': 'Exception',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',
  'menu.exception.trigger': 'Trigger',
  'menu.account': 'Account',
  'menu.account.center': 'Account Center',
  'menu.account.settings': 'Account Settings',
  'menu.account.trigger': 'Trigger Error',
  'menu.account.logout': 'Logout',
  'menu.editor': 'Graphic Editor',
  'menu.editor.flow': 'Flow Editor',
  'menu.editor.mind': 'Mind Editor',
  'menu.editor.koni': 'Koni Editor',
};
