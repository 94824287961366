import React from 'react';
import {
  Router as DefaultRouter,
  Route,
  Switch,
  StaticRouter,
} from 'react-router-dom';
import dynamic from 'umi/dynamic';
import renderRoutes from 'umi/lib/renderRoutes';
import history from '@@/history';
import RendererWrapper0 from '/home/vsts/work/1/s/frontend/src/pages/.umi-production/LocaleWrapper.jsx';
import _dvaDynamic from 'dva/dynamic';

const Router = require('dva/router').routerRedux.ConnectedRouter;

const routes = [
  {
    path: '/user',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "layouts__UserLayout" */ '../../layouts/UserLayout'),
          LoadingComponent: require('/home/vsts/work/1/s/frontend/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/UserLayout').default,
    routes: [
      {
        name: 'login',
        path: '/user/login',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__user__login" */ '../user/login'),
              LoadingComponent: require('/home/vsts/work/1/s/frontend/src/components/PageLoading/index')
                .default,
            })
          : require('../user/login').default,
        exact: true,
      },
      {
        name: 'passwordReset',
        path: '/user/password-reset',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__user__passwordReset" */ '../user/passwordReset'),
              LoadingComponent: require('/home/vsts/work/1/s/frontend/src/components/PageLoading/index')
                .default,
            })
          : require('../user/passwordReset').default,
        exact: true,
      },
      {
        name: 'passwordReset',
        path: '/user/password-reset-confirm/:uid/:token/:email',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__user__passwordReset_Confirm" */ '../user/passwordReset_Confirm'),
              LoadingComponent: require('/home/vsts/work/1/s/frontend/src/components/PageLoading/index')
                .default,
            })
          : require('../user/passwordReset_Confirm').default,
        exact: true,
      },
      {
        name: 'signup',
        path: '/user/signup',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__user__signUp" */ '../user/signUp'),
              LoadingComponent: require('/home/vsts/work/1/s/frontend/src/components/PageLoading/index')
                .default,
            })
          : require('../user/signUp').default,
        exact: true,
      },
    ],
  },
  {
    name: 'landing',
    path: '/landing',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "p__landing__landingHome" */ '../landing/landingHome'),
          LoadingComponent: require('/home/vsts/work/1/s/frontend/src/components/PageLoading/index')
            .default,
        })
      : require('../landing/landingHome').default,
    exact: true,
  },
  {
    name: 'pricing',
    path: '/pricing',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "p__landing__pricing" */ '../landing/pricing'),
          LoadingComponent: require('/home/vsts/work/1/s/frontend/src/components/PageLoading/index')
            .default,
        })
      : require('../landing/pricing').default,
    exact: true,
  },
  {
    name: '483_download',
    path: '/483_download',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "p__landing__download483" */ '../landing/download483'),
          LoadingComponent: require('/home/vsts/work/1/s/frontend/src/components/PageLoading/index')
            .default,
        })
      : require('../landing/download483').default,
    exact: true,
  },
  {
    name: 'Terms and Conditions',
    path: '/terms-and-conditions',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "p__landing__termsNconditions" */ '../landing/termsNconditions'),
          LoadingComponent: require('/home/vsts/work/1/s/frontend/src/components/PageLoading/index')
            .default,
        })
      : require('../landing/termsNconditions').default,
    exact: true,
  },
  {
    name: 'Privacy Policy',
    path: '/privacy-policy',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "p__landing__privacypolicy" */ '../landing/privacypolicy'),
          LoadingComponent: require('/home/vsts/work/1/s/frontend/src/components/PageLoading/index')
            .default,
        })
      : require('../landing/privacypolicy').default,
    exact: true,
  },
  {
    name: 'Cookie Policy',
    path: '/cookies-tracking-notice',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "p__landing__cookiepolicy" */ '../landing/cookiepolicy'),
          LoadingComponent: require('/home/vsts/work/1/s/frontend/src/components/PageLoading/index')
            .default,
        })
      : require('../landing/cookiepolicy').default,
    exact: true,
  },
  {
    name: 'inspectors',
    path: '/inspectors/:id',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "p__Public__inspectors" */ '../Public/inspectors'),
          LoadingComponent: require('/home/vsts/work/1/s/frontend/src/components/PageLoading/index')
            .default,
        })
      : require('../Public/inspectors').default,
    exact: true,
  },
  {
    name: 'inspector details',
    path: '/inspectorDetail/:id',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "p__Public__inspectorDetails" */ '../Public/inspectorDetails'),
          LoadingComponent: require('/home/vsts/work/1/s/frontend/src/components/PageLoading/index')
            .default,
        })
      : require('../Public/inspectorDetails').default,
    exact: true,
  },
  {
    path: '/',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "layouts__SecurityLayout" */ '../../layouts/SecurityLayout'),
          LoadingComponent: require('/home/vsts/work/1/s/frontend/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/SecurityLayout').default,
    routes: [
      {
        path: '/',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "layouts__BasicLayout" */ '../../layouts/BasicLayout'),
              LoadingComponent: require('/home/vsts/work/1/s/frontend/src/components/PageLoading/index')
                .default,
            })
          : require('../../layouts/BasicLayout').default,
        authority: ['admin', 'user'],
        routes: [
          {
            path: '/',
            redirect: '/inspections',
            exact: true,
          },
          {
            name: 'Data Typing',
            path: '/data_typing',
            icon: 'line-chart',
            authority: ['2222'],
            routes: [
              {
                path: '/data_typing',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__dataTyping__list__model.ts' */ '/home/vsts/work/1/s/frontend/src/pages/dataTyping/list/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../dataTyping/list'),
                      LoadingComponent: require('/home/vsts/work/1/s/frontend/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../dataTyping/list').default,
                exact: true,
              },
            ],
          },
          {
            name: 'USFDA Inspections',
            path: '/inspections',
            icon: 'project',
            authority: ['9999', '1111', '6666', '1'],
            routes: [
              {
                path: '/inspections',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__inspections__list__model.ts' */ '/home/vsts/work/1/s/frontend/src/pages/inspections/list/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../inspections/list'),
                      LoadingComponent: require('/home/vsts/work/1/s/frontend/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../inspections/list').default,
                exact: true,
              },
              {
                path: '/inspections/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__inspections__details__model.ts' */ '/home/vsts/work/1/s/frontend/src/pages/inspections/details/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../inspections/details'),
                      LoadingComponent: require('/home/vsts/work/1/s/frontend/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../inspections/details').default,
                exact: true,
              },
            ],
          },
          {
            name: 'MHRA/EU Inspections',
            path: '/Mhra_Eu_inspection',
            icon: 'project',
            authority: ['9999', '1111', '6666'],
            routes: [
              {
                path: '/Mhra_Eu_inspection',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__Mhra_Eu_inspections__list__model.ts' */ '/home/vsts/work/1/s/frontend/src/pages/Mhra_Eu_inspections/list/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Mhra_Eu_inspections/list'),
                      LoadingComponent: require('/home/vsts/work/1/s/frontend/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Mhra_Eu_inspections/list').default,
                exact: true,
              },
            ],
          },
          {
            name: 'CAT',
            path: '/comparative_analysis',
            icon: 'line-chart',
            authority: ['9999', '1111'],
            routes: [
              {
                path: '/comparative_analysis',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__comparativeAnalysis__list__model.ts' */ '/home/vsts/work/1/s/frontend/src/pages/comparativeAnalysis/list/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../comparativeAnalysis/list'),
                      LoadingComponent: require('/home/vsts/work/1/s/frontend/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../comparativeAnalysis/list').default,
                exact: true,
              },
            ],
          },
          {
            name: 'Analytics',
            path: '/global_analytics',
            icon: 'line-chart',
            authority: ['9999', '1111', '6666'],
            routes: [
              {
                path: '/global_analytics',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__globalAnalytics__list__model.ts' */ '/home/vsts/work/1/s/frontend/src/pages/globalAnalytics/list/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../globalAnalytics/list'),
                      LoadingComponent: require('/home/vsts/work/1/s/frontend/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../globalAnalytics/list').default,
                exact: true,
              },
            ],
          },
          {
            name: 'Daily Companion',
            path: '/dailyCompanion',
            icon: 'undo',
            authority: ['9999', '1111', '6666'],
            routes: [
              {
                path: '/dailyCompanion',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__dailyCompanion__list__model.ts' */ '/home/vsts/work/1/s/frontend/src/pages/dailyCompanion/list/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../dailyCompanion/list'),
                      LoadingComponent: require('/home/vsts/work/1/s/frontend/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../dailyCompanion/list').default,
                exact: true,
              },
              {
                path: '/dailyCompanion/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__dailyCompanion__details__model.ts' */ '/home/vsts/work/1/s/frontend/src/pages/dailyCompanion/details/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../dailyCompanion/details'),
                      LoadingComponent: require('/home/vsts/work/1/s/frontend/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../dailyCompanion/details').default,
                exact: true,
              },
            ],
          },
          {
            name: 'Recently Added 483s',
            path: '/recently483',
            icon: 'rise',
            authority: ['9999', '1111', '6666'],
            routes: [
              {
                path: '/recently483',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__RecentlyAdded483__list__model.ts' */ '/home/vsts/work/1/s/frontend/src/pages/RecentlyAdded483/list/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../RecentlyAdded483/list'),
                      LoadingComponent: require('/home/vsts/work/1/s/frontend/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../RecentlyAdded483/list').default,
                exact: true,
              },
            ],
          },
          {
            name: 'investigators',
            path: '/investigators',
            icon: 'contacts',
            authority: ['9999', '1111', '6666', '1'],
            routes: [
              {
                path: '/investigators',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__investigators__list__model.ts' */ '/home/vsts/work/1/s/frontend/src/pages/investigators/list/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../investigators/list'),
                      LoadingComponent: require('/home/vsts/work/1/s/frontend/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../investigators/list').default,
                exact: true,
              },
              {
                path: '/investigators/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__investigators__details__model.ts' */ '/home/vsts/work/1/s/frontend/src/pages/investigators/details/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../investigators/details'),
                      LoadingComponent: require('/home/vsts/work/1/s/frontend/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../investigators/details').default,
                exact: true,
              },
            ],
          },
          {
            name: 'sites',
            path: '/sites',
            icon: 'global',
            authority: ['9999', '1111', '6666', '1'],
            routes: [
              {
                path: '/sites',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__sites__list__model.ts' */ '/home/vsts/work/1/s/frontend/src/pages/sites/list/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../sites/list'),
                      LoadingComponent: require('/home/vsts/work/1/s/frontend/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../sites/list').default,
                exact: true,
              },
              {
                path: '/sites/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__sites__details__model.ts' */ '/home/vsts/work/1/s/frontend/src/pages/sites/details/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../sites/details'),
                      LoadingComponent: require('/home/vsts/work/1/s/frontend/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../sites/details').default,
                exact: true,
              },
            ],
          },
          {
            name: 'Trending483',
            path: '/trending_483',
            icon: 'download',
            authority: ['9999', '1111', '6666', '1'],
            routes: [
              {
                path: '/trending_483',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__trending_483__list__model.ts' */ '/home/vsts/work/1/s/frontend/src/pages/trending_483/list/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../trending_483/list'),
                      LoadingComponent: require('/home/vsts/work/1/s/frontend/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../trending_483/list').default,
                exact: true,
              },
            ],
          },
          {
            name: 'companyProfile',
            path: '/companyProfile',
            icon: 'bank',
            authority: ['9999', '1111', '6666', '1'],
            routes: [
              {
                path: '/companyProfile',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__companyprofile__list__model.ts' */ '/home/vsts/work/1/s/frontend/src/pages/companyprofile/list/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../companyprofile/list'),
                      LoadingComponent: require('/home/vsts/work/1/s/frontend/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../companyprofile/list').default,
                exact: true,
              },
              {
                path: '/companyProfile/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__companyprofile__details__model.ts' */ '/home/vsts/work/1/s/frontend/src/pages/companyprofile/details/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../companyprofile/details'),
                      LoadingComponent: require('/home/vsts/work/1/s/frontend/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../companyprofile/details').default,
                exact: true,
              },
            ],
          },
          {
            name: 'warning_letters',
            path: '/warning-letters',
            icon: 'file-text',
            authority: ['9999', '1111', '6666', '1'],
            routes: [
              {
                path: '/warning-letters',
                exact: true,
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__warningLetters__list__model.ts' */ '/home/vsts/work/1/s/frontend/src/pages/warningLetters/list/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../warningLetters/list'),
                      LoadingComponent: require('/home/vsts/work/1/s/frontend/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../warningLetters/list').default,
              },
              {
                path: '/warning-letters/:id',
                exact: true,
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__warningLetters__details__model.ts' */ '/home/vsts/work/1/s/frontend/src/pages/warningLetters/details/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../warningLetters/details'),
                      LoadingComponent: require('/home/vsts/work/1/s/frontend/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../warningLetters/details').default,
              },
            ],
          },
          {
            name: 'observations',
            path: '/observations',
            icon: 'reconciliation',
            authority: ['9999', '1111', '6666', '1'],
            routes: [
              {
                path: '/observations',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__observations__searchOptions__model.ts' */ '/home/vsts/work/1/s/frontend/src/pages/observations/searchOptions/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../observations/searchOptions'),
                      LoadingComponent: require('/home/vsts/work/1/s/frontend/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../observations/searchOptions').default,
                exact: true,
              },
              {
                path: '/observations/results',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__observations__searchResults__model.ts' */ '/home/vsts/work/1/s/frontend/src/pages/observations/searchResults/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../observations/searchResults'),
                      LoadingComponent: require('/home/vsts/work/1/s/frontend/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../observations/searchResults').default,
                exact: true,
              },
            ],
          },
          {
            name: 'observationsQS',
            path: '/observationsQS',
            icon: 'reconciliation',
            authority: ['9999', '1111', '6666'],
            routes: [
              {
                path: '/observationsQS',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__observationsQS__searchOptionsQualitySystem__model.ts' */ '/home/vsts/work/1/s/frontend/src/pages/observationsQS/searchOptionsQualitySystem/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../observationsQS/searchOptionsQualitySystem'),
                      LoadingComponent: require('/home/vsts/work/1/s/frontend/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../observationsQS/searchOptionsQualitySystem')
                      .default,
                exact: true,
              },
              {
                path: '/observationsQS/results',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__observationsQS__searchResults__model.ts' */ '/home/vsts/work/1/s/frontend/src/pages/observationsQS/searchResults/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../observationsQS/searchResults'),
                      LoadingComponent: require('/home/vsts/work/1/s/frontend/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../observationsQS/searchResults').default,
                exact: true,
              },
            ],
          },
          {
            name: 'observationsFTS',
            path: '/freeText',
            icon: 'file-search',
            authority: ['9999', '1111', '6666', '1'],
            routes: [
              {
                path: '/freeText',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__freeText__model.ts' */ '/home/vsts/work/1/s/frontend/src/pages/freeText/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../freeText'),
                      LoadingComponent: require('/home/vsts/work/1/s/frontend/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../freeText').default,
                exact: true,
              },
            ],
          },
          {
            name: 'observationsCS',
            path: '/citationSearch',
            icon: 'security-scan',
            authority: ['9999', '1111', '6666', '1'],
            routes: [
              {
                path: '/citationSearch',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__citations__model.ts' */ '/home/vsts/work/1/s/frontend/src/pages/citations/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../citations'),
                      LoadingComponent: require('/home/vsts/work/1/s/frontend/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../citations').default,
                exact: true,
              },
            ],
          },
          {
            name: 'Recall Data',
            path: '/recall',
            icon: 'undo',
            authority: ['9999', '1111', '6666', '1'],
            routes: [
              {
                path: '/recall',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__recall__list__model.ts' */ '/home/vsts/work/1/s/frontend/src/pages/recall/list/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../recall/list'),
                      LoadingComponent: require('/home/vsts/work/1/s/frontend/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../recall/list').default,
                exact: true,
              },
            ],
          },
          {
            name: 'MeDeusa',
            path: '/medical_devices',
            icon: 'radar-chart',
            authority: ['1111'],
            routes: [
              {
                name: 'MyProfile',
                path: '/medical_devices/MyProfile',
                icon: 'user',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__medicalDevice__myProfile__model.ts' */ '/home/vsts/work/1/s/frontend/src/pages/medicalDevice/myProfile/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../medicalDevice/myProfile'),
                      LoadingComponent: require('/home/vsts/work/1/s/frontend/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../medicalDevice/myProfile').default,
                exact: true,
              },
              {
                path: '/medical_devices/site/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__medicalDevice__site__model.ts' */ '/home/vsts/work/1/s/frontend/src/pages/medicalDevice/site/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../medicalDevice/site'),
                      LoadingComponent: require('/home/vsts/work/1/s/frontend/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../medicalDevice/site').default,
                exact: true,
                layout: false,
              },
              {
                name: 'Recall',
                path: '/medical_devices/recalls',
                icon: 'undo',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__medicalDevice__recalls__list__model.ts' */ '/home/vsts/work/1/s/frontend/src/pages/medicalDevice/recalls/list/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../medicalDevice/recalls/list'),
                      LoadingComponent: require('/home/vsts/work/1/s/frontend/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../medicalDevice/recalls/list').default,
                exact: true,
              },
              {
                name: 'MAUDE',
                path: '/medical_devices/maude',
                icon: 'warning',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__medicalDevice__maude__list__model.ts' */ '/home/vsts/work/1/s/frontend/src/pages/medicalDevice/maude/list/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../medicalDevice/maude/list'),
                      LoadingComponent: require('/home/vsts/work/1/s/frontend/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../medicalDevice/maude/list').default,
                exact: true,
              },
              {
                name: 'Registration_listing',
                path: '/medical_devices/registration_listing',
                icon: 'form',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__medicalDevice__medicalDeviceRegistrationSearch__list__model.ts' */ '/home/vsts/work/1/s/frontend/src/pages/medicalDevice/medicalDeviceRegistrationSearch/list/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../medicalDevice/medicalDeviceRegistrationSearch/list'),
                      LoadingComponent: require('/home/vsts/work/1/s/frontend/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../medicalDevice/medicalDeviceRegistrationSearch/list')
                      .default,
                exact: true,
              },
            ],
          },
          {
            name: 'drugAnalytics',
            path: '/drugAnalytics',
            icon: 'number',
            authority: ['9999', '1111', '6666', '1'],
            routes: [
              {
                path: '/drugAnalytics',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__druganalytics__list__model.ts' */ '/home/vsts/work/1/s/frontend/src/pages/druganalytics/list/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../druganalytics/list'),
                      LoadingComponent: require('/home/vsts/work/1/s/frontend/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../druganalytics/list').default,
                exact: true,
              },
              {
                path: '/drugAnalytics/:id',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__druganalytics__details__model.ts' */ '/home/vsts/work/1/s/frontend/src/pages/druganalytics/details/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../druganalytics/details'),
                      LoadingComponent: require('/home/vsts/work/1/s/frontend/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../druganalytics/details').default,
                exact: true,
              },
            ],
          },
          {
            name: 'WatchList',
            path: '/WatchList',
            icon: 'star',
            authority: ['9999', '1111', '6666'],
            routes: [
              {
                path: '/WatchList',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__watchList__list__model.ts' */ '/home/vsts/work/1/s/frontend/src/pages/watchList/list/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../watchList/list'),
                      LoadingComponent: require('/home/vsts/work/1/s/frontend/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../watchList/list').default,
                exact: true,
              },
            ],
          },
          {
            name: 'NewsFeed',
            path: '/news_feed',
            icon: 'sound',
            authority: ['9999', '1111', '6666'],
            routes: [
              {
                path: '/news_feed',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__newsFeed__list__model.ts' */ '/home/vsts/work/1/s/frontend/src/pages/newsFeed/list/model.ts').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../newsFeed/list'),
                      LoadingComponent: require('/home/vsts/work/1/s/frontend/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../newsFeed/list').default,
                exact: true,
              },
            ],
          },
          {
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__404" */ '../404'),
                  LoadingComponent: require('/home/vsts/work/1/s/frontend/src/components/PageLoading/index')
                    .default,
                })
              : require('../404').default,
            exact: true,
          },
        ],
      },
      {
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__404" */ '../404'),
              LoadingComponent: require('/home/vsts/work/1/s/frontend/src/components/PageLoading/index')
                .default,
            })
          : require('../404').default,
        exact: true,
      },
    ],
  },
  {
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () => import(/* webpackChunkName: "p__404" */ '../404'),
          LoadingComponent: require('/home/vsts/work/1/s/frontend/src/components/PageLoading/index')
            .default,
        })
      : require('../404').default,
    exact: true,
  },
];
window.g_routes = routes;
const plugins = require('umi/_runtimePlugin');
plugins.applyForEach('patchRoutes', { initialValue: routes });

export { routes };

export default class RouterWrapper extends React.Component {
  unListen() {}

  constructor(props) {
    super(props);

    // route change handler
    function routeChangeHandler(location, action) {
      plugins.applyForEach('onRouteChange', {
        initialValue: {
          routes,
          location,
          action,
        },
      });
    }
    this.unListen = history.listen(routeChangeHandler);
    // dva 中 history.listen 会初始执行一次
    // 这里排除掉 dva 的场景，可以避免 onRouteChange 在启用 dva 后的初始加载时被多执行一次
    const isDva =
      history.listen
        .toString()
        .indexOf('callback(history.location, history.action)') > -1;
    if (!isDva) {
      routeChangeHandler(history.location);
    }
  }

  componentWillUnmount() {
    this.unListen();
  }

  render() {
    const props = this.props || {};
    return (
      <RendererWrapper0>
        <Router history={history}>{renderRoutes(routes, props)}</Router>
      </RendererWrapper0>
    );
  }
}
